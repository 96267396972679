import React, { useState } from 'react';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth, db } from '../firebase-config';
import { Button, TextField, Box, Typography, Container, Link } from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';

const SignUp: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null); // Error handling
  const navigate = useNavigate();

  const googleProvider = new GoogleAuthProvider();

  const signUp = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setError(null); // Reset error state
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const { uid, email: userEmail } = userCredential.user;

      // Create user document in Firestore
      const userRef = doc(db, 'users', uid);
      await setDoc(userRef, {
        email: userEmail,
        allowed: false, // You can add other default fields as needed
      });

      navigate('/home');
    } catch (error) {
      console.error(error);
      setError('Failed to create an account. Please try again.');
    }
  };

  const signUpWithGoogle = async () => {
    setError(null); // Reset error state
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const { uid, email } = result.user;

      // Create or merge user document in Firestore
      const userRef = doc(db, 'users', uid);
      await setDoc(userRef, {
        email: email,
        allowed: false, // You can add other default fields as needed
      }, { merge: true });

      navigate('/home');
    } catch (error) {
      console.error(error);
      setError('Failed to sign up with Google. Please try again.');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography component="h1" variant="h4" sx={{ marginBottom: 2, color: '#003D3D', fontSize: '2rem' }}>
          Join Us Today
        </Typography>
        <Typography component="p" variant="body1" sx={{ marginBottom: 4, color: '#555', fontSize: '1.1rem' }}>
          Start your tango journey with us. Sign up now to unlock exclusive sequences and lessons.
        </Typography>

        {/* Sign-Up Form */}
        <Box component="form" onSubmit={signUp} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ backgroundColor: '#e0e0e0', borderRadius: 1, fontSize: '1.1rem' }}
            InputLabelProps={{ style: { fontSize: '1.1rem' } }} // Increase font size of the label
            InputProps={{ style: { fontSize: '1.1rem' } }} // Increase font size of the input text
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ backgroundColor: '#e0e0e0', borderRadius: 1, fontSize: '1.1rem' }}
            InputLabelProps={{ style: { fontSize: '1.1rem' } }} // Increase font size of the label
            InputProps={{ style: { fontSize: '1.1rem' } }} // Increase font size of the input text
          />

          {/* Error Message */}
          {error && (
            <Typography sx={{ color: 'red', mt: 2 }}>
              {error}
            </Typography>
          )}

          {/* Submit button */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3, mb: 2, backgroundColor: '#008080', color: 'white', fontWeight: 'bold', textTransform: 'none', fontSize: '1.1rem',
              '&:hover': {
                backgroundColor: '#006666',
              },
            }}
          >
            Create Account
          </Button>

          {/* Sign-Up with Google */}
          <Button
            onClick={signUpWithGoogle}
            fullWidth
            variant="contained"
            sx={{
              mt: 1, mb: 2, backgroundColor: '#E57373', color: 'white', fontWeight: 'bold', textTransform: 'none', fontSize: '1.1rem',
              '&:hover': {
                backgroundColor: '#D32F2F',
              },
            }}
          >
            Sign Up with Google
          </Button>
        </Box>

        {/* Redirect to Sign In */}
        <Typography variant="body2" sx={{ mt: 2, fontSize: '1rem' }}>
          Already have an account?{' '}
          <Link component={RouterLink} to="/signin" variant="body2" sx={{ color: '#008080', textDecoration: 'underline', fontSize: '1rem' }}>
            Sign In
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default SignUp;
