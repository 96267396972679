import React from 'react';
import { Box, List, ListItem, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

interface HamburgerMenuProps {
  onClose: () => void;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ onClose }) => {
  const { user, isAdmin, signOut } = useAuth(); // Destructure signOut from useAuth

  const handleSignOut = async () => {
    try {
      await signOut();
      onClose(); // Close the menu after signing out
    } catch (error) {
      console.error('Failed to sign out:', error);
    }
  };

  const renderLinks = () => (
    <List>
      <ListItem button onClick={onClose}>
        <Button
          fullWidth
          component={RouterLink}
          to="/home"
          sx={{
            backgroundColor: '#00A3A3', // Teal color
            color: 'white',
            '&:hover': {
              backgroundColor: '#008080',
            },
          }}
        >
          Courses
        </Button>
      </ListItem>
      {!user ? (
        <>
          <ListItem button onClick={onClose}>
            <Button
              fullWidth
              component={RouterLink}
              to="/signin"
              sx={{
                backgroundColor: '#FF7F50', // Coral color
                color: 'white',
                '&:hover': {
                  backgroundColor: '#FF6347',
                },
              }}
            >
              Sign In
            </Button>
          </ListItem>
          <ListItem button onClick={onClose}>
            <Button
              fullWidth
              component={RouterLink}
              to="/signup"
              sx={{
                backgroundColor: '#004D40', // Dark teal color
                color: 'white',
                '&:hover': {
                  backgroundColor: '#003d33',
                },
              }}
            >
              Sign Up
            </Button>
          </ListItem>
        </>
      ) : (
        <>
          {isAdmin && (
            <ListItem button onClick={onClose}>
              <Button
                fullWidth
                component={RouterLink}
                to="/admin"
                sx={{
                  backgroundColor: '#20B2AA', // Light sea green color
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#3CB371',
                  },
                }}
              >
                Admin
              </Button>
            </ListItem>
          )}

          {/* Add Profile Link */}
          <ListItem button onClick={onClose}>
            <Button
              fullWidth
              component={RouterLink}
              to="/profile"
              sx={{
                backgroundColor: '#4CAF50', // Green color
                color: 'white',
                '&:hover': {
                  backgroundColor: '#388E3C',
                },
              }}
            >
              Profile
            </Button>
          </ListItem>

          <ListItem button onClick={handleSignOut}> {/* Use handleSignOut on click */}
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: '#FF4500', // Orange red color
                color: 'white',
                '&:hover': {
                  backgroundColor: '#FF6347',
                },
              }}
            >
              Sign Out
            </Button>
          </ListItem>
        </>
      )}
    </List>
  );

  return (
    <Box
      sx={{
        width: 250,
        backgroundColor: '#004D4D',
        height: '100%',
        padding: '16px',
      }}
      role="presentation"
      onClick={onClose}
      onKeyDown={onClose}
    >
      {renderLinks()}
    </Box>
  );
};

export default HamburgerMenu;
